import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Sections from "../components/Sections";

const PageWithSections = ({ data }) => {
  const { markdownRemark: post } = data;

  const { language, title, sections } = post.frontmatter;

  return (
    <Layout language={language}>
      <SEO
        lang={language}
        keywords={[`Hibot`, `Robotics`, `Tokyo`, `Smart inspection`]}
        title={title}
      />
      <Sections sections={sections} language={language} />
    </Layout>
  );
};

PageWithSections.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PageWithSections;

export const PageWithSectionsQuery = graphql`
  query PageWithSections($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        language
        title
        sections {
          type
          cssClass
          title
          menuTitle
          body
          backgroundVideo
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          buttons {
            text
            url
            type
            modalContent
          }
          solutions {
            icon {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
        }
      }
    }
  }
`;
